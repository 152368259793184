.allure_qr_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1011px;
    .allure_qr_log{
        margin: 25px 0px 70px;
        @media (max-width: 1024px) and (orientation: landscape) { 
            margin: 0px 0 30px 0;
         }
    }
    .allure_qr_x{
        font-size: 72px;
        font-family: 'BigCaslonRegular';
        margin: auto 40px;
        @media (max-width: 768px){
            margin: auto 30px;
            font-size: 50px;
        }
        @media (min-width: 768px) and (orientation: landscape) { 
            margin: auto 60px;
         }
    }
    .riley_logo{
        width: 337px;
        height: 135px;
        @media (max-width: 768px){
            width: 250px;
            height: auto;
        }
    }
    .allure_qr_title{
        padding: 0 20px;
        font-size: 64px;
        font-family: 'BigCaslonRegular';
        font-weight: normal;
        text-align: center;
        margin-bottom: 55px;
        line-height: 120%;
        @media (max-width: 769px){
            margin-bottom: 30px;
        }
    }
    .allure_qr_image{
        margin: 56px 0;
        .allure_qr_image-qr{
            width: 324px;
            height: 324px;
        }
    }
    .allure_qr_footer{
        .allure_qr_footer-text{
            font-size: 18px;
            font-family: 'PainRegular';
            font-weight: normal;
            text-align: center;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #595959;
        }
    }
}
@media (min-width: 700px) and (orientation: landscape) { 
    .allure_qr_container{
        .allure_qr_image{
            margin: 26px 0;
            .allure_qr_image-qr{
                width: 324px;
                height: 324px;
            }
        }
       
    }
 }