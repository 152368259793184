.allure_log{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .allure_logo_image{
        width: 307px;
        height: 125.48px;    
        @media (max-width: 768px){
            width: 250px;
            height: auto;    
        }
    }
    .allure_logo_text{
        width: 96px;
        height: 16.59px;
    }
}